import type { AppProps } from 'next/app'
import Head from 'next/head'
import { createTheme, ThemeProvider } from '@mui/material'
import { Open_Sans } from 'next/font/google'

import './index.css'

const openSans = Open_Sans({ subsets: ['latin', 'latin-ext'] })
const theme = createTheme({
  typography: {
    fontFamily: openSans.style.fontFamily
  }
})

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Aplikacja Demokracja" />
        <title>Aplikacja Demokracja</title>
      </Head>
      <main className={openSans.className}>
        <ThemeProvider theme={theme}>
          <Component {...pageProps} />
        </ThemeProvider>
      </main>
    </>
  )
}
